import React from "react";

import NavDemo from "../components/NavDemo"
import LayoutDemo from "../components/layoutDemo";
import Seo from "../components/seo";

const DemoPage = () => {
    return (
        <LayoutDemo>
            <Seo
                seo={{
                    metaTitle: "Demo",
                    metaDescription: "It looks like you got to the demo page",
                }}
            />
            <NavDemo/>
            <main className="">
            </main>
        </LayoutDemo>
    )
};

export default DemoPage;
