import React from "react"
import { Link } from "gatsby"

import LogoIcon from "../assets/svg/rentware-logo-icon.svg"

const NavDemo = (props) => (
    <nav className="bg-white border-b border-[#EDEDEF]">
        <ul className="flex flex-wrap items-center h-16 mx-auto text-sm max-w-[1440px] md:h-20 md:justify-start">
            <li className="w-auto p-4">
                <Link to="/">
                    <LogoIcon className="w-16 h-10 md:inline-block md:" />
                </Link>
            </li>
            <li className="items-center hidden h-full p-4 font-bold text-right border-b lg:flex demo-nav-link--active">
                <Link to="/demo" className="">Demo</Link>
            </li>
        </ul>
    </nav>
)
    
export default NavDemo