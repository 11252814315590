import React from "react";
import PropTypes from "prop-types";

export default function LayoutDemo({ children }) {
  // useUsercentrics();
  return (
    <>
      <>{children}</>
    </>
  )
}

LayoutDemo.propTypes = {
  children: PropTypes.node.isRequired,
};